import fetchWithHeaders from "../../fetchInterceptor";
import * as Sentry from "@sentry/nextjs";

export const updateCustomerApi = async (payload, isUpdate) => {
    const url = `${process.env.BACKEND_URL}box/customer-setting`;
    const postOrPut = isUpdate ? "PUT" : "POST";
    if (payload?.address) {
        payload.address.street =
            payload?.address?.street_name || payload?.address?.street || "";
    }
    try {
        const response = await fetchWithHeaders(url, {
            body: JSON.stringify(payload),
            method: postOrPut,
        });
        const data = await response?.json();
        Sentry.captureMessage(`${url} success ${JSON.stringify(data)}`);


        return data;
    } catch (error) {
        Sentry.captureException(error);
        return error;
    }
};
// Function to fetch user data using access token
export const getUserDataApi = async () => {
    const url = `${process.env.BACKEND_URL}customers/`;
    try {
        const response = await fetchWithHeaders(url, {
            method: "GET",
        });
        const data = await response?.json();
        Sentry.captureMessage(`${url} success ${JSON.stringify(data)}`);


        return data;
    } catch (error) {
        Sentry.captureException(error);
        return error?.response?.data;
    }
};

export const updatePasswordApi = async (payload) => {
    const url = `${process.env.BACKEND_URL}core/password-reset/`;
    try {
        const response = await fetchWithHeaders(url, {
            body: JSON.stringify(payload),
            method: "POST",
        });
        const data = await response?.json();
        Sentry.captureMessage(`${url} success ${JSON.stringify(data)}`);


        return data;
    } catch (error) {
        Sentry.captureException(error);
        return error;
    }
};

import fetchWithHeaders from "../../fetchInterceptor";
import * as Sentry from "@sentry/nextjs";

export const postCreateReturnOrder = async (payload) => {
    const url = `${process.env.BACKEND_URL}box/return-order`;
    try {
        const response = await fetchWithHeaders(url, {
            body: JSON.stringify(payload),
            method: "POST",
        });
        const data = await response?.json();
        Sentry.captureMessage(`${url} success ${JSON.stringify(data)}`);


        return data;
    } catch (error) {
        Sentry.captureException(error);
        return error?.response?.data;
    }
};
